
import { defineComponent } from "vue";
import NavBar from "./components/Navigation/NavBar.vue";
import MyFooter from "./components/Navigation/Footer.vue";

export default defineComponent({
  name: "App",
  components: {
    NavBar,
    MyFooter,
  },
});
