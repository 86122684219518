import { createWebHistory, createRouter } from "vue-router";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    alias: "/packages",
    name: "packages",
    component: () => import("@/components/PackageList.vue"),
  },
  {
    path: "/completedPackages",
    name: "completedPackages",
    component: () => import("@/components/PackageListCompleted.vue"),
  },
  {
    path: "/items",
    name: "items",
    component: () => import("@/components/ItemList.vue"),
  },
  {
    path: "/salary-calculator",
    name: "salary-calculator",
    component: () => import("@/components/SalaryCalculator.vue"),
  },
  {
    path: "/addPackage",
    name: "addPackage",
    component: () => import("@/components/AddPackage.vue"),
  },
  {
    path: "/package/:id/:modify?",
    name: "package",
    component: () => import("@/components/PackageDetails.vue"),
  },
  {
    path: "/item/:id",
    name: "item",
    component: () => import("@/components/ItemDetails.vue"),
  },
  {
    path: "/itemsPackage/:id/:package",
    name: "itemsPackage",
    component: () => import("@/components/ItemsInPackageDetails.vue"),
  },
  {
    path: "/addItem/:packageId?",
    name: "addItem",
    component: () => import("@/components/AddItem.vue"),
  },
  {
    path: "/addItems",
    name: "addItems",
    component: () => import("@/components/AddItems.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/Login.vue"),
  },
  {
    path: "/:pathMatch(.*)?",
    name: "notFound",
    component: () => import("@/components/NotFound.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/components/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    return { name: "login" };
  }
});

export default router;
