/* eslint-disable no-console */
import { notify } from "@kyvg/vue3-notification";
import Modal from "@/components/CustomElement/Modal.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";

class AutoHTML {
  autoResize_textarea(event: Event): void {
    const target = event.target as HTMLInputElement;
    target.style.height = "auto";
    target.style.height = `${target.scrollHeight}px`;
  }

  errorNotification(error: any, callback?: () => void) {
    console.error(error);
    notify({
      title: "Error",
      text: error,
      type: "error",
      duration: 10000,
    });
    if (callback) callback();
  }

  successNotification(success: any) {
    notify({
      title: "Success",
      text: success,
      type: "success",
      duration: 3000,
    });
  }

  confirm(
    props: { question: string; okText?: string; cancelText?: string },
    onOK?: (param: any) => void,
    onCan?: (param: any) => void
  ) {
    const { reveal, onConfirm, onCancel } = createConfirmDialog(
      Modal,
      props as any
    );

    if (onOK) onConfirm(onOK);
    if (onCan) onCancel(onCan);

    reveal();
  }
}

export default new AutoHTML();
