
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "modal",
  props: {
    question: String,
    ok: { type: String, default: "OK" },
    cancel: { type: String, default: "Cancel" },
  },
  data() {
    return {
      initValue: this.value ?? true,
    };
  },
  methods: {},
  setup() {
    const htmlElement = ref(null);
    return {
      htmlElement,
    };
  },
});
