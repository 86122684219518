import axios from "axios";

// https://devless.gitbook.io/devless-docs-1-3-0/interacting-with-devless/http-api
// Status https://devless.gitbook.io/devless-docs-1-3-0/interacting-with-devless/devless-status-codes
class Devless {
  url: string;
  token: string;
  serviceName: string;
  readonly timeout = 5000;
  readonly cacheTimeout = 2 * 24 * 60 * 60 * 1000;

  constructor(url?: string, token?: string, serviceName?: string) {
    this.url = url ? url : "https://devless.hackathonsaustralia.com/";
    this.token = token ? token : "82007b9359ace895b641e8f9a5e0d1c7";
    this.serviceName = serviceName ? serviceName : "ViCart";
  }

  get(table: string, data?: QueryData) {
    return this.queryData("ViCart", table, data);
  }

  getById(table: string, id: string) {
    return this.queryData("ViCart", table, { where: [["id", id]], size: 1 });
  }

  update(
    table: string,
    identifierField: string,
    identifierValue: string,
    data: any
  ) {
    return this.updateData(
      "ViCart",
      table,
      identifierField,
      identifierValue,
      data
    );
  }

  delete(table: string, identifierField: string, identifierValue: string) {
    return this.deleteData("ViCart", table, identifierField, identifierValue);
  }

  deleteAll(table: string) {
    return this.deleteData("ViCart", table);
  }

  search(table: string, name: string, value: any) {
    return this.queryData("ViCart", table, {
      search: [name, value],
    });
  }

  searchWithConditions(
    table: string,
    name: string,
    value: any,
    ...whereCondition: Condition[]
  ) {
    return this.queryData("ViCart", table, {
      search: [name, value],
      where: whereCondition,
    });
  }

  addData(serviceName: string, tableName: string, data: object): Promise<any> {
    const config = {
      headers: {
        "Devless-token": this.token,
      },
    };
    return axios
      .post(
        this.url + "/api/v1/service/" + serviceName + "/db",
        {
          resource: [
            {
              name: tableName,
              field: [data],
            },
          ],
        },
        config
      )
      .then(function (response) {
        if (response.data.status_code === 609) return response;
        else
          throw new Error(
            "Error during Devless's add: '" +
              response.data.message +
              "'. " +
              JSON.stringify(response)
          );
      });
  }

  queryData(
    serviceName: string,
    tableName: string,
    params?: QueryData
  ): Promise<any> {
    let queryParams = "";
    for (const item in params) {
      if (item == "where")
        params.where?.forEach((condition) => {
          queryParams += "&" + item + "=" + condition;
        });
      else {
        queryParams += "&" + item + "=" + params[item];
      }
    }

    const url =
      this.url +
      "/api/v1/service/" +
      serviceName +
      "/db?table=" +
      tableName +
      queryParams;
    return axios({
      url: url,
      method: "get",
      headers: {
        "Devless-token": this.token,
      },
      timeout: this.timeout,
    }).then(function (response) {
      response.data.queryParams = url;
      if (response.data.status_code === 625) return response;
      else
        throw new Error(
          "Error during Devless's query: '" +
            response.data.message +
            "'. " +
            JSON.stringify(response)
        );
    });
  }

  //Update data in service table
  updateData(
    serviceName: string,
    tableName: string,
    identifierField: string,
    identifierValue: string,
    data: any
  ): Promise<any> {
    const config = {
      headers: {
        "Devless-token": this.token,
      },
    };
    return axios
      .patch(
        this.url + "/api/v1/service/" + serviceName + "/db",
        {
          resource: [
            {
              name: tableName,
              params: [
                {
                  where: identifierField + "," + identifierValue,
                  data: [data],
                },
              ],
            },
          ],
        },
        config
      )
      .then(function (response) {
        if (
          response.data.status_code === 619 ||
          response.data.status_code === 629
        )
          return response;
        else
          throw new Error(
            "Error during Devless's update: '" +
              response.data.message +
              "'. " +
              JSON.stringify(response)
          );
      });
  }

  //Delete data in service table
  deleteData(
    serviceName: string,
    tableName: string,
    identifierField?: string,
    identifierValue?: string
  ): Promise<any> {
    return axios({
      method: "delete",
      headers: {
        "Devless-token": this.token,
      },
      url: this.url + "/api/v1/service/" + serviceName + "/db",
      data: {
        resource: [
          {
            name: tableName,
            params: [
              identifierField
                ? {
                    where: identifierField + "," + identifierValue,
                    delete: true,
                  }
                : { delete: true },
            ],
          },
        ],
      },
    }).then(function (response) {
      if (
        response.data.status_code === 614 ||
        response.data.status_code === 636 ||
        response.data.status_code === 639
      )
        return response;
      else
        throw new Error(
          "Error during Devless's delete: '" +
            response.data.message +
            "'. " +
            JSON.stringify(response)
        );
    });
  }

  //Authentication

  //actions: signup, login
  authenticate(action: AUTHENTICATION, params: Array<string>): Promise<any> {
    return axios({
      method: "post",
      headers: {
        "Devless-token": this.token,
      },
      url: this.url + "/api/v1/service/devless/rpc?action=" + action,
      data: {
        jsonrpc: "2.0",
        method: "devless",
        id: "1000",
        params: params,
      },
    }).then(function (response) {
      if (response.data.status_code != 637)
        throw new Error(
          "Error during Devless's authenticate: '" +
            response.data.message +
            "'. " +
            JSON.stringify(response)
        );
      switch (action) {
        case AUTHENTICATION.LOGIN:
          localStorage.setItem("user", JSON.stringify(response.data.payload));
          break;
        case AUTHENTICATION.LOGOUT:
          localStorage.removeItem("user");
          break;
      }
      return response;
    });
  }

  //General RPC call

  //RPC call
  rpc(serviceName: string, action: string, params: any) {
    axios({
      method: "post",
      headers: {
        "Devless-token": this.token,
      },
      url:
        this.url + "/api/v1/service/" + serviceName + "/rpc?action=" + action,
      data: {
        jsonrpc: "2.0",
        method: serviceName,
        id: "1000",
        params: params,
      },
    }).then(function (response) {
      return response;
    });
  }
}

export enum AUTHENTICATION {
  REGISTER = "signUp",
  LOGIN = "login",
  LOGOUT = "logout",
  LOGGED = "getProfile",
}

class Condition extends Array<any> {
  toString = (): string => {
    if (this.length > 2)
      throw new Error(
        `Codition should have 2 elements which are key and value. Currently, it has ${
          this.length
        } elements: ${this.join(", ")}`
      );
    return `${this[0]},${this[1]}`;
  };
}

export interface QueryData {
  orderBy?: string;
  where?: Condition[];
  orWhere?: Condition;
  size?: number;
  offset?: number;
  search?: Condition;
  greaterThan?: Condition;
  greaterThanEqual?: Condition;
  lessThan?: Condition;
  lessThanEqual?: Condition;
  desc?: string;
  asc?: string;
}

export default new Devless();
