import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import Notifications from "@kyvg/vue3-notification";
import BootstrapVue3 from "bootstrap-vue-3";
import AutoHTML from "@/helpers/AutoHTML";
import LoadScript from "vue-plugin-load-script";
import * as ConfirmDialog from "vuejs-confirm-dialog";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "bootstrap5-toggle/css/bootstrap5-toggle.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./assets/css/ui.css";

const app = createApp(App);

app
  .use(router)
  .use(Notifications)
  .use(BootstrapVue3)
  .use(ConfirmDialog)
  .use(LoadScript);

app.config.errorHandler = (err) => {
  AutoHTML.errorNotification(err);
};

app.mount("#app");
