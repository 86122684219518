
import AutoHTML from "@/helpers/AutoHTML";
import Devless, { AUTHENTICATION } from "@/services/Devless";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "nav-bar",
  methods: {
    logout() {
      Devless.authenticate(AUTHENTICATION.LOGOUT, [])
        .then(() => {
          this.$router.replace("/login");
        })
        .catch(AutoHTML.errorNotification);
    },
  },
  computed: {
    path(): string {
      return useRoute().name as string;
    },
  },
});
