import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal",
  tabindex: "-1"
}
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": "Close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.question), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-secondary",
            "data-bs-dismiss": "modal",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
          }, _toDisplayString(_ctx.cancel), 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-primary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('confirm')))
          }, _toDisplayString(_ctx.ok), 1)
        ])
      ])
    ])
  ]))
}